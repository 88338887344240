<template>
    <div :class="['sc-table', `sc-table--${tablesize}`]">
        <!-- 表格顶部操作区 -->
        <div class="sc-table__header">
            <!-- 搜索表单 -->
            <div class="left">
                <el-form
                    size="default"
                    v-if="searchColumns.length"
                    :model="search"
                    ref="searchForm"
                    :inline="true"
                    class="sc-table__search"
                >
                    <el-form-item
                        v-for="(item, index) in searchColumns"
                        :key="index"
                        :label="item.label"
                        :prop="item.prop"
                    >
                        <!-- 输入框 -->
                        <el-input
                            v-if="!item.type || item.type === 'input'"
                            v-model="search[item.prop]"
                            :placeholder="'请输入' + item.label"
                            clearable
                            @keyup.enter="handleSearch"
                            style="width: 144px"
                        />

                        <!-- 数字输入框 -->
                        <el-input-number
                            v-if="item.type === 'number'"
                            v-model="search[item.prop]"
                            :min="item.min ?? 0"
                            :placeholder="'请输入' + item.label"
                            style="width: 144px"
                            @keyup.enter="handleSearch"
                        />

                        <!-- 选择器 -->
                        <el-select
                            v-if="item.type === 'select'"
                            v-model="search[item.prop]"
                            :placeholder="'请选择' + item.label"
                            clearable
                            style="width: 144px"
                        >
                            <el-option
                                v-for="dict in item.dicData"
                                :key="dict.value"
                                :label="dict.label"
                                :value="dict.value"
                            />
                        </el-select>

                        <!-- 日期选择器 -->
                        <el-date-picker
                            v-if="item.type === 'date'"
                            v-model="search[item.prop]"
                            type="date"
                            :placeholder="'请选择' + item.label"
                            style="width: 144px"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleSearch" size="default">
                            <el-icon><Search /></el-icon>
                            查询
                        </el-button>
                        <el-button @click="handleReset" size="default">
                            <el-icon><Refresh /></el-icon>
                            重置
                        </el-button>
                    </el-form-item>
                </el-form>
                <slot name="left"></slot>
            </div>
            <div class="right">
                <el-button
                    v-if="option.addBtn !== false && permissionList?.addBtn"
                    type="primary"
                    @click="rowAdd"
                >
                    <el-icon><Plus /></el-icon>
                    新增
                </el-button>

                <!-- 使用v-if和$slots来控制默认批量删除按钮的显示 -->
                <el-button
                    v-if="!$slots['menu-left'] && option.batchDelete !== false && permissionList?.delBtn"
                    type="danger"
                    :disabled="!selectedRows.length"
                    @click="handleBatchDelete"
                    size="default"
                >
                    <el-icon><Delete /></el-icon>
                    批量删除
                </el-button>

                <slot name="menu-left"></slot>
                <slot name="right"></slot>
            </div>
        </div>

        <!-- 表格主体 -->
        <el-table
            ref="table"
            :data="data"
            v-loading="loading"
            style="width: 100%;min-height: 400px;"
            size="default"
            border
            :empty-text="emptyText"
            :stripe="option.stripe !== false"
            @selection-change="handleSelectionChange"
            @select="selectionChange"
        >
            <!-- 复选框列 -->
            <el-table-column
                v-if="showSelection"
                type="selection"
                width="44"
                align="center"
                fixed="left"
                :selectable="hasSelectPermission"
            >   
            </el-table-column>
            
            <!-- 序号列 -->
            <el-table-column
                v-if="showIndex"
                type="index"
                label="序号"
                width="80"
                align="center"
                :index="calculateIndex"
                fixed="left"
            />

            <template v-for="(column, index) in visibleColumns" :key="index">
                <!-- 操作列 -->
                <el-table-column
                    v-if="column.type === 'operate'"
                    :label="column.label"
                    :width="column.width ? column.width * 0.8 : undefined"
                    :align="column.align || 'center'"
                    :fixed="column.fixed"
                >
                    <template #default="scope">
                        <!-- 默认按钮 -->
                        <template v-for="(btn, i) in getVisibleButtons(column.buttons)" :key="i">
                            <el-button
                                size="default"
                                :type="btn.type || 'primary'"
                                :link="btn.link"
                                @click="handleBtnClick(btn, scope.row)"
                            >
                                {{ btn.label }}
                            </el-button>
                        </template>

                        <!-- 添加扩展按钮插槽 -->
                        <slot name="menu-extend" v-bind="scope"></slot>
                    </template>
                </el-table-column>

                <!-- 普通列 -->
                <el-table-column
                    v-else
                    :prop="column.prop"
                    :label="column.label"
                    :width="column.width ? column.width * 0.8 : undefined"
                    :align="column.align"
                    :fixed="column.fixed"
                >
                    <template #default="scope">
                        <!-- 优先使用字段的具名插槽 -->
                        <slot
                            v-if="$slots[column.prop]"
                            :name="column.prop"
                            v-bind="{ row: scope.row, column: column }"
                        />
                        <!-- 没有具名插槽时使用默认渲染逻辑 -->
                        <template v-else>
                            <template v-if="column.type === 'multi-line'">
                                <div class="cell-content">
                                    <div class="primary-text">{{ scope.row[column.prop] }}</div>
                                    <div class="secondary-text">{{ scope.row[column.secondProp] }}</div>
                                </div>
                            </template>
                            <template v-else-if="column.formatter">
                                {{ column.formatter(scope.row, column) }}
                            </template>
                            <template v-else>
                                <template v-if="column.dicUrl || column.dicData">
                                    <div class="flex gap-2">
                                        <template v-if="column.dicUrl && dictCache[column.dicUrl]">
                                            <template v-if="scope.row[column.prop] && String(scope.row[column.prop]).includes(',')">
                                                <el-tag
                                                    v-for="value in String(scope.row[column.prop]).split(',')"
                                                    :key="value"
                                                    type="info"
                                                    size="default"
                                                >
                                                    {{ getDictLabel(value.trim(), column.dicUrl) }}
                                                </el-tag>
                                            </template>
                                            <template v-else>
                                                <el-tag type="info" size="default">
                                                    {{ getDictLabel(scope.row[column.prop], column.dicUrl) }}
                                                </el-tag>
                                            </template>
                                        </template>
                                        <template v-else-if="column.dicData">
                                            <el-tag type="info" size="default">
                                                {{ getDictLabelFromData(scope.row[column.prop], column.dicData) }}
                                            </el-tag>
                                        </template>
                                        <template v-else>
                                            <el-skeleton-item variant="text" style="width: 48px" />
                                        </template>
                                    </div>
                                </template>
                                <template v-else>
                                    {{ formatColumnValue(scope.row[column.prop], column) }}
                                </template>
                            </template>
                        </template>
                    </template>
                </el-table-column>
            </template>

            <!-- 添加自定义操作列 -->
            <el-table-column
                v-if="$slots.menu"
                label="操作"
                :width="option.menuWidth ? option.menuWidth * 0.8 : 160"
                fixed="right"
                align="center"
            >
                <template #default="scope">
                    <slot name="menu" v-bind="scope"></slot>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="sc-table__pagination" v-if="page">
            <el-pagination
                size="default"
                background
                v-model:current-page="page.currentPage"
                v-model:page-size="page.pageSize"
                :total="page.total"
                :page-sizes="[10, 20, 30, 50]"
                layout="total, sizes, prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </div>

        <!-- 弹窗 -->
        <el-dialog
            v-model="dialogVisible"
            :title="dialogTitle"
            width="48%"
            :close-on-click-modal="option.dialogClickModal !== false"
            :destroy-on-close="true"
            @close="handleDialogClose"
        >
            <component
                :is="dialogComponent"
                ref="form"
                :option="option"
                v-model="formData"
                :col-span="12"
                @submit="handleSubmit"
                @cancel="dialogVisible = false"
            >
                <!-- 只传递带有 -add 后缀的插槽给 add 组件 -->
                <template v-for="(_, name) in $slots" :key="name">
                    <template v-if="dialogType === 'add' && name.endsWith('-add')">
                        <slot 
                            :name="name" 
                            v-bind="{ row: formData, col: getColumnByProp(name.replace('-add', '')) }"
                        />
                    </template>
                </template>
            </component>
        </el-dialog>
    </div>
</template>

<script>
import { Plus, Delete, Search, Refresh } from '@element-plus/icons-vue'
import ScAdd from './sc-add.vue'
import ScEdit from './sc-edit.vue'
import ScView from './sc-view.vue'
import axios from '@/axios'
import { hasOperationPermission, shouldOnlyOperateOwnData } from '@/utils/staticPermission'

export default {
    name: 'ScTable',
    components: {
        Plus,
        Delete,
        Search,
        Refresh,
        ScAdd,
        ScEdit,
        ScView
    },
    props: {
        option: {
            type: Object,
            required: true
        },
        data: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
        emptyText: {
            type: String,
            default: '暂无数据'
        },
        tablesize: {
            type: String,
            default: 'large',
        },
        page: { 
            type: Object,
            default: null
        },
        permissionList: {
            type: Object,
            default: () => ({
                addBtn: true,
                viewBtn: true,
                editBtn: true,
                delBtn: true
            })
        },
        rowSave: {
            type: Function,
            default: null
        },
        rowUpdate: {
            type: Function,
            default: null
        },
        rowDel: {
            type: Function,
            default: null
        },
        search: {
            type: Object,
            default: () => ({})
        },
        form: {
            type: Object,
            default: () => ({})
        },
        // Add module prop for permission checking
        permissionModule: {
            type: String,
            default: ''
        }
    },
    computed: {
        showSelection() {
            return this.option.selection !== false
        },
        showIndex() {
            return this.option.index !== false
        },
        visibleColumns() {
            let columns = this.option.column.filter(col => !col.hide)
            
            if (!this.$slots.menu && this.option.operateBtn !== false) {
                columns.push({
                    type: 'operate',
                    label: '操作',
                    width: this.option.menuWidth || 300,
                    fixed: 'right',
                    buttons: [
                        {
                            label: '查看',
                            type: 'primary',
                            link: true,
                            function: 'rowView',
                            permission: 'viewBtn'
                        },
                        {
                            label: '编辑',
                            type: 'primary',
                            link: true,
                            function: 'rowEdit',
                            permission: 'editBtn'
                        },
                        {
                            label: '删除',
                            type: 'danger',
                            link: true,
                            function: 'handleRowDel',
                            permission: 'delBtn'
                        }
                    ]
                })
            }
            
            return columns
        },
        // 获取需要搜索的列
        searchColumns() {
            return this.option.column.filter(item => item.search)
        },
        dialogTitle() {
            const titles = {
                add: '新增',
                edit: '编辑',
                view: '查看'
            }
            return titles[this.dialogType]
        },
        dialogComponent() {
            const components = {
                add: 'sc-add',
                edit: 'sc-edit',
                view: 'sc-view'
            }
            return components[this.dialogType]
        },
        // 检查当前用户是否只能操作自己的数据
        onlyOperateOwnData() {
            if (!this.permissionModule) return false
            return shouldOnlyOperateOwnData(this.permissionModule)
        }
    },
    emits: ['on-load', 'selection-change', 'row-save', 'row-update', 'row-del', 'update:search', 'update:form'],
    data() {
        return {
            selectedRows: [],
            isAllSelected: false,
            dialogVisible: false,
            dialogType: '',
            formData: {},
            viewData: {},  // 新增查看数据对象
            size: 'small',
            dictCache: {}
        }
    },
    created() {
        this.loadDictionaryData()
    },
    mounted() {
        // 组件挂载时自动加载数据
        this.$emit('on-load', this.search);
    },
    methods: {
        handleSizeChange(val) {
            this.page.pageSize = val
            this.$emit('on-load',this.page, this.search)
        },
        handleCurrentChange(val) {
            this.page.currentPage = val
            this.$emit('on-load',this.page, this.search)
        },
        // 处理多选变化
        handleSelectionChange(val) {
            this.selectedRows = val;
            this.isAllSelected = val.length === this.data.length;
            this.$emit('selection-change', val);
        },
        // 计算序号
        calculateIndex(index) {
            if (this.page) {
                const currentPage = this.page.currentPage;
                const pageSize = this.page.pageSize;
                
                return (currentPage - 1) * pageSize + index + 1;
            }
            return index + 1;
        },
        // 格式化列值
        formatColumnValue(value, column) {
            if (column.type === 'number' && (value === null || value === undefined)) {
                return 0
            }
            return value
        },
        // 处理搜索
        handleSearch() {
            if (this.page) {
                this.page.currentPage = 1
            }
            this.$emit('on-load',this.page, this.search)
        },

        // 重置搜索
        handleReset() {
            this.$refs.searchForm.resetFields()
            this.$emit('update:search', {})
            this.$emit('on-load', this.page, {})
        },
        // 添加获取可见按钮的方法
        getVisibleButtons(buttons) {
            if (!buttons) return []
            return buttons.filter(btn => {
                // 根据按钮的权限属性检查权限
                return this.permissionList[btn.permission]
            })
        },
        handleBtnClick(btn, row) {
            this[btn.function](row)
        },
        // 处理新增按钮点击
        rowAdd() {
            this.dialogType = 'add'
            this.formData = {}
            this.dialogVisible = true
        },

        // 处理查看按钮点击
        rowView(row) {
            this.dialogType = 'view'
            this.viewData = { ...row }  // 使用viewData
            this.formData = this.viewData  // 设置formData为viewData
            this.dialogVisible = true
        },

        // 处理编辑按钮点击
        rowEdit(row) {
            debugger
            this.dialogType = 'edit'
            this.formData = { ...row }  // 直接使用当前行数据
            this.dialogVisible = true
        },

        // 处理表单提交
        async handleSubmit(formData, done, loading) {
            this.dialogVisible = false
            this.$emit(this.dialogType === 'add' ? 'row-save' : 'row-update', formData, done, loading)
        },

        // 处理单行删除，从操作列按钮触发
        handleRowDel(row) {
            this.$emit('row-del', row);
        },

        // 处理批量删除，从顶部按钮触发
        handleBatchDelete() {
            if (!this.selectedRows.length) return
            const ids = this.selectedRows.map(row => row.id).join(',')
            this.$emit('row-del', ids)
        },

        // 添加 toggleSelection 方法
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.table.toggleRowSelection(row)
                })
            } else {
                this.$refs.table.clearSelection()
            }
        },
        
        // 检查是否有选择权限
        hasSelectPermission(row) {
            // 如果没有指定模块，则默认允许选择
            if (!this.permissionModule) return true
            
            // 使用 hasOperationPermission 检查是否有权限操作该行数据
            // 这里使用 'select' 作为操作类型，可以根据需要调整
            return hasOperationPermission(row, this.permissionModule, 'select')
        },
        
        // 行选择前的钩子函数，用于控制哪些行可以被选择
        selectionChange(row, selected) {
            return true
        },
        
        async loadDictionaryData() {
            for (const column of this.option.column) {
                if (column.dicUrl && !this.dictCache[column.dicUrl]) {
                    try {
                        const response = await axios.get(column.dicUrl)
                        if (response.data.success) {
                            this.dictCache[column.dicUrl] = response.data.data
                        }
                    } catch (error) {
                        console.error('Failed to load dictionary data:', error)
                    }
                }
            }
        },
        getDictLabel(value, dicUrl) {
            const dictItem = this.dictCache[dicUrl].find(
                item => item.dictKey == value || item.dictKey == Number(value)
            )
            return dictItem ? dictItem.dictValue : value
        },
        getDictLabelFromData(value, dicData) {
            const dictItem = dicData.find(
                item => item.value === value || item.value === Number(value)
            )
            return dictItem ? dictItem.label : value
        },
        // 根据 prop 获取列配置
        getColumnByProp(prop) {
            return this.option.column.find(col => col.prop === prop) || {}
        },
        // 根据权限过滤数据
        filterDataByPermission(dataList, operation = 'select') {
            if (!this.permissionModule || !Array.isArray(dataList)) return dataList
            
            // 如果用户不受限制，返回所有数据
            if (!this.onlyOperateOwnData) return dataList
            
            // 过滤出用户有权限操作的数据
            return dataList.filter(item => this.hasSelectPermission(item))
        },
        
        // 暴露给父组件的方法，用于检查单个数据项是否可选
        checkSelectPermission(row) {
            return this.hasSelectPermission(row)
        },

        // 关闭弹窗时清理数据
        handleDialogClose() {
            this.formData = {}
            this.viewData = {}
            this.dialogVisible = false
        }
    }
}
</script>
<style>
.el-table__border-bottom-patch, .el-table .el-table__border-left-patch {
    background-color: var(--el-table-border-color);
    left: 0;
    position: absolute;
    z-index: calc(var(--el-table-index) + 2);
    display: none !important;
}
</style>
<style lang="scss" scoped> 
:deep(.el-table__border-bottom-patch,.el-table .el-table__border-left-patch) {
    display: none !important;
}
:deep(.action-buttons) {
  display: flex;
  gap: 6px;
  
  .action-btn {
    height: 28px;
    padding: 0 12px;
    border: none;
    background: transparent;
    
    .btn-content {
      display: flex;
      align-items: center;
      gap: 3px;
    }

    .icon-wrapper {
      width: 26px;
      height: 26px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      .el-icon {
        font-size: 13px;
      }
    }

    span {
      font-size: 13px;
      white-space: nowrap;
    }

    &.message-btn {
     
      .icon-wrapper {
        background: rgba(255, 171, 93, 0.1);
        color: #FFAB5D;
      }
      &:hover {
        .icon-wrapper {
          background: rgba(255, 171, 93, 0.2);
        }
      }
    }

    &.add-btn {
      .icon-wrapper {
        width: 80px;
        background: #525CE519 ; 
        color: #525CE5;
        font-weight: 400;
      }
      &:hover {
        .icon-wrapper {
          background: rgba(67, 24, 255, 0.2);
        }
      }
    }
  }
}
:deep(.el-table__border-bottom-patch, .el-table__border-left-patch) {
    display: none !important;
}
.sc-table {
    font-family: 'Nunito', sans-serif;
    background-color: transparent !important;
    // 去除表格整体边框
    border: none !important;
    
    // 去除表格单元格边框
    :deep(.el-table__cell) {
        border: none !important;
    }

    

    :deep(.el-table.el-table--border .el-table__inner-wrapper::after) {
  display: none !important;
}
    // 去除表格的伪元素边框
    &::before,
    &::after {
        display: none !important;
    }

    // 去除横向边框线
    :deep(.el-table__inner-wrapper::before,.el-table__inner-wrapper:after) {
        display: none !important;
    }

   
    // 去除内部边框线
    :deep(.el-table__border) {
        display: none !important;
    }

    // 除固定列的阴影（如果有的话）
    :deep(.el-table__fixed-right::before),
    :deep(.el-table__fixed::before) {
        display: none !important;
    }
    .el-table  {
  background-color: transparent !important;
 
  // 去除表格边框
  &::before,
  &::after {
    display: none !important;
  }
  
  // 隐藏表头
  // 表头样式
  :deep(.el-table__header-wrapper) {
    display: block !important; // 显示表头
    tr{
        background-color: transparent !important;  
    }
    th.el-table__cell {
      background-color: transparent !important;
      border: none !important;
      padding: 11px !important;
      font-weight: 400 !important;
      color: #1E253C !important;
      font-size: 11px;
      white-space: nowrap;
      // 表头排序图标样式
      .sort-caret-wrapper {
        height: 16px;
        width: 16px;
        position: relative;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        margin-left: 3px;
        
        &::after {
          content: '';
          position: absolute;
          right: 0;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid #666;
        }
      }

      // 当排序列的样式
      &.ascending .sort-caret-wrapper::after {
        transform: rotate(180deg);
      }
    }
  }

  // 去除表头底部边框
  :deep(.el-table__header) {
    border-bottom: none;
  }
  
  // 表格主体
  ::v-deep .el-table__body-wrapper {
    background-color: transparent !important;
    
    
}
::v-deep .el-table__body {
    background-color: transparent !important;
    border-collapse: separate !important;
  border-spacing: 0 10px !important;
}
 
::v-deep td.el-table__cell {
    background-color: white !important;
    border: 16px !important;
    padding: 13px 13px;
    height: 56px;
    white-space: normal !important;
    max-width: 0;
    font-size: 11px;
    // 第一个单元格圆角
    &:first-child {
        border-top-left-radius: 13px !important;
        border-bottom-left-radius: 13px !important;
    }

    // 最后一个单元格圆角
    &:last-child {
        border-top-right-radius: 13px !important;
        border-bottom-right-radius: 13px !important;
    }
    }

    // 行间距
    & + .el-table__row {
    margin-top: 6px;
    }

    
}  



  // 默认样式
  &--large {
        :deep(.custom-checkbox) {
            .el-checkbox__inner {
                width: 16px;
                height: 16px;
                border-radius: 3px;
                border: 1px solid #dcdfe6;
                
                &::after {
                    height: 8px;
                    width: 4px;
                    left: 5px;
                    top: 2px;
                    border-color: white; // 勾选的颜色改为白色
                }
            }

            // 选中状态的样式
            &.is-checked .el-checkbox__inner {
                background-color: #4086F4; // 使用蓝色背景
                border-color: #4086F4;
            }

            // 鼠标悬停状态
            &:hover .el-checkbox__inner {
                border-color: #4086F4;
            }
        }
  }
}

.sc-table {
    :deep(.el-table__header-wrapper) {
        margin-bottom: 0;  // 移除底部边距
    }

    :deep(.el-table__body-wrapper) {
        margin-top: -14px;  // 设置一个较小的顶部边距
    }

    // 如果上面的方法不生效，可以尝试这个
    :deep(.el-table__header) {
        margin-bottom: 6px !important;  // 直接设置表头底部边距
    }

    // 确保第一行的样式正确
    :deep(.el-table__row:first-child) {
        td {
        padding-top: 6px;  // 调整第一行的顶部内边距
        }
    }

    // 如果使用了 border-spacing，也可以调整它
    :deep(.el-table__body) {
        border-spacing: 0 6px !important;  // 调整行间距
    }
  // 表格容器样式
  ::v-deep .el-table {
    background-color: #fff;
    border: none !important;
    
    // 移除默认边框
    &::before,
    &::after {
      display: none;
    }
    
    // 表格行边框和背景色
     // 减少表头和内容之间的间距
   
    // 表头样式
    .el-table__header-wrapper {
      th.el-table__cell {
        background-color: #fff;
        border: none;
        font-weight: 600;
        color: #8F95B2;
        padding: 13px;
        font-size: 14px;
        
        // 表头排序图标
        .sort-caret-wrapper {
          height: 13px;
          .sort-caret.ascending {
            border-bottom-color: #8F95B2;
          }
          .sort-caret.descending {
            border-top-color: #8F95B2;
          }
        }
      }
    }
    td.el-table__cell{
        background-color: #fff !important;
    }

    // 表格主体
    .el-table__body-wrapper {
      background-color: transparent;
      
      // 表格行
      .el-table__row {
        background-color: transparent; 
        cursor: pointer;
       
      
        &:hover {
            background-color: #525CE519  !important;
        }
        
        
        // 单元格样式
        td.el-table__cell {
          padding: 13px;
          border-bottom: 1px solid #525CE519;
          font-size: 15px;
          color: #2B3674;
          
          // 移除单元格边框
          &::before {
            display: none;
          }
        }
      }
      
      // 斑马纹样式
      .el-table__row--striped {
        background-color: #F8F9FE;
      }
    }
    
    // 选择框样式
    .el-checkbox__inner {
      border-radius: 3px;
      border-color: #E0E5F2;
    }
  }
  
  // 状态标签样式
  .status-tag {
    padding: 5px 10px;
    border-radius: 6px;
    font-size: 10px;
    
    &.unpaid {
      color: #6C5DD3;
      background: rgba(108, 93, 211, 0.1);
    }
    
    &.pending {
      color: #FFB547;
      background: rgba(255, 181, 71, 0.1);
    }
    
    &.paid {
      color: #05CD99;
      background: rgba(5, 205, 153, 0.1);
    }
  }
  
  // 操作按钮样式
  .action-button {
    color: #2B3674;
    
    &:hover {
      color: #6C5DD3;
    }
  }
  
  // 分页器样式
  .sc-table__pagination {
    margin-top: 16px;
    
    :deep(.el-pagination) {
      .el-pagination__total,
      .el-pagination__sizes {
        margin-right: 13px;
      }
      
      .el-pagination__jump {
        margin-left: 13px;
      }
      
      .el-pager li {
        border-radius: 3px;
        
        &.active {
          background-color: #6C5DD3;
        }
      }
    }
  }
}

:deep(.flex.gap-2) {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    width: 100%;
}

:deep(.el-tag) {
    margin: 2px 0;
    white-space: nowrap;
}

// 修改所有复选框样式（包括表头和行内）
:deep(.el-checkbox) {
  .el-checkbox__inner {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #dcdfe6;
    
    &::after {
      height: 8px;
      width: 4px;
      left: 5px;
      top: 2px;
      border-color: white;
    }
  }

  // 选中状态的样式
  &.is-checked .el-checkbox__inner {
    background-color: #4086F4;
    border-color: #4086F4;
  }

  // 鼠标悬停状态
  &:hover .el-checkbox__inner {
    border-color: #4086F4;
  }
}

// 特别处理表头复选框
:deep(.el-table__header-wrapper) {
  .el-checkbox {
    .el-checkbox__inner {
      width: 16px !important;
      height: 16px !important;
    }
  }
}

// 调整表格头部操作区域的间距
.sc-table__header {
  margin-bottom: 8px;
  
  .left, .right {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  :deep(.el-form-item) {
    margin-bottom: 8px;
    margin-right: 8px;
  }
  
  :deep(.el-button) {
    padding: 6px 12px;
    font-size: 13px;
  }
}
</style> 